import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Intro from 'components/intro'
import { graphql } from 'gatsby'

const Container = styled.div`
  height: 60vh;
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--lg);
`

const FourZeroFourPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.fourzerofourJson.title}</title>
      <meta name="description" content={data.fourzerofourJson.description} />
    </Helmet>
    <Container>
      <Intro intro={data.fourzerofourJson.intro} withCTA />
    </Container>
  </Layout>
)

FourZeroFourPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default FourZeroFourPage

export const query = graphql`
  query FourZeroFourPageQuery {
    fourzerofourJson {
      title
      description
      intro {
        heading
        copy
        link
        cta
      }
    }
  }
`
